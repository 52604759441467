
function remSetSize() {
  // let html = document.getElementsByTagName('html')[0];
  const html = document.querySelector('html');
  // document.documentElement.clientWidth
  const { width } = html.getBoundingClientRect();
  html.style.fontSize = `${width / 16}px`;
  window.onresize = remSetSize;
}


export default remSetSize;


import Vue from 'vue';

// element-ui
import 'element-ui/lib/theme-chalk/index.css'
import {
  Loading,
  Table,
  TableColumn,
  DatePicker,
  TabPane,
  Image,
  Pagination,
  Row,
  Col,
  Tabs,
  Form,
  FormItem,
  Input,
  Select,
  Option,
  Dialog,
  Message,
} from 'element-ui'



// element ui
Vue.use(Table)
Vue.use(Pagination)
Vue.use(Row)
Vue.use(Col)
Vue.use(Tabs)
Vue.use(TableColumn)
Vue.use(DatePicker)
Vue.use(TabPane)
Vue.use(Image)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Input)
Vue.use(Select)
Vue.use(Option)
Vue.use(Dialog)
Vue.use(Loading.directive)
Vue.use(Dialog)

Vue.prototype.$message = Message;
Vue.prototype.$loading = Loading.service


// eslint-disable-next-line no-unused-vars
import ElButton from 'element-ui/lib/button';
Vue.component(ElButton.name, ElButton);
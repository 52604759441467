<template>
  <div class="main-content">
    <router-view></router-view>
  </div>
</template>

<script>
  export default {
    name: 'app',
    mounted () {

    },

  }
</script>

<style>
  .main-content {
    width: 100%;
    height: 100%;
  }

</style>
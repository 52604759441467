import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        userName: '' || window.localStorage.getItem('user'),
        auth: '' || window.localStorage.getItem('auth'),
    },
    mutations: {
        saveUserNameMutation(state, b) {
            state.userName = b;
            window.localStorage.setItem('user', b);
        },
    },
    actions: {
        saveUserNameAction(context, b) {
            context.commit('saveUserNameMutation', b);
        },
    },
    getters: {
        getUserName(state) {
            return state.userName;
        },
        getAuth(state) {
            return state.auth;
        },
    },
    modules: {
    },
});

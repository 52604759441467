import Vue from 'vue'
import Index from './Index.vue'
import router from '../../router'
import store from '../../store'
import '../../axios'
import '../../utils'

// css
import '../../assets/css/reset.css'
import '../../assets/css/animate.css'

import rem from './../../assets/js/rem';

rem();

import './../../ui/ui.element'



// 路由守卫
router.beforeEach((to, from, next) => {
  if (to.path === '/' || to.path === '/login') {
    next();
  } else {
    console.log(to.path)
    if (window.localStorage.getItem('auth')) {
      next();
    } else {
      next({ path: '/login' });
    }
  }
});




new Vue({
  render: h => h(Index),
  router,
  store,
}).$mount('#index')

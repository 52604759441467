export const ymdhmsTime = (time) => {
    if (time) {
        var oDate = new Date();
        oDate.setTime(time);

        var y = oDate.getFullYear();
        var m = oDate.getMonth() + 1;
        var d = oDate.getDate();

        var h = oDate.getHours();
        var mi = oDate.getMinutes();
        var s = oDate.getSeconds();

        return y + '-' + m + '-' + d + ' ' + h + ':' + mi + ':' + s;
    }
}

export const isMobile = () => {
    let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
    return flag;
}


export const isEmpty = (obj) => {
    // eslint-disable-next-line eqeqeq
    if (typeof obj === 'undefined' || obj == null || obj == '') {
        return true;
    }
    return false;
}

export const arrayToStringWithComma = (s) => {
    var str = "";
    // eslint-disable-next-line no-undef
    s.forEach((currentValue) => {
        str += currentValue + ",";
    })

    //去掉最后一个逗号(如果不需要去掉，就不用写)
    if (str.length > 0) {
        str = str.substr(0, str.length - 1);
    }
    return str;
}

export const getQueryString = (param) => { //param为要获取的参数名 注:获取不到是为null
    var currentUrl = window.location.href; //获取当前链接
    var arr = currentUrl.split("?");//分割域名和参数界限
    if (arr.length > 1) {
        arr = arr[1].split("&");//分割参数
        for (var i = 0; i < arr.length; i++) {
            var tem = arr[i].split("="); //分割参数名和参数内容
            if (tem[0] == param) {
                let p = tem[1];
                // vue-router hash 模式 字符串裁剪
                if (p.substr(p.length - 1, 1) == '/' && p.substr(p.length - 2, 1) == '#') {
                    p = p.substr(0, p.length - 2)
                }
                return p;
            }
        }
        return null;
    } else {
        return null;
    }
}


export const stringToByte = (str) => {
    var bytes = new Array();
    var len, c;
    len = str.length;
    for (var i = 0; i < len; i++) {
        c = str.charCodeAt(i);
        if (c >= 0x010000 && c <= 0x10FFFF) {
            bytes.push(((c >> 18) & 0x07) | 0xF0);
            bytes.push(((c >> 12) & 0x3F) | 0x80);
            bytes.push(((c >> 6) & 0x3F) | 0x80);
            bytes.push((c & 0x3F) | 0x80);
        } else if (c >= 0x000800 && c <= 0x00FFFF) {
            bytes.push(((c >> 12) & 0x0F) | 0xE0);
            bytes.push(((c >> 6) & 0x3F) | 0x80);
            bytes.push((c & 0x3F) | 0x80);
        } else if (c >= 0x000080 && c <= 0x0007FF) {
            bytes.push(((c >> 6) & 0x1F) | 0xC0);
            bytes.push((c & 0x3F) | 0x80);
        } else {
            bytes.push(c & 0xFF);
        }
    }
    return bytes;


}

export const dataBase64toFile = (dataurl, filename) => {//将base64转换为文件

    var arr = dataurl.split(','),

        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);

    while (n--) {

        u8arr[n] = bstr.charCodeAt(n);

    }

    return new File([u8arr], filename, {type: 'zip'});

}
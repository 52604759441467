import Vue from 'vue'
import VueRouter from 'vue-router'


import routes from './route.config'


Vue.use(VueRouter)

export default new VueRouter({
    mode: 'hash', 
    scrollBehavior: () => ({
        y: 0
    }), // 滚动条滚动的行为，不加这个默认就会记忆原来滚动条的位置
    routes
})


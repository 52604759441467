import {ymdhmsTime, isMobile, isEmpty, arrayToStringWithComma, getQueryString, dataBase64toFile} from "./utils.method";
import Vue from 'vue'

// 其他页面在使用 utils  就可以了的时候直接  this.$utils就可以了
Vue.prototype.$utils = {
	ymdhmsTime,
	isMobile,
	isEmpty,
	arrayToStringWithComma,
	getQueryString,
	dataBase64toFile
}
import axios from 'axios'
import Vue from 'vue'

axios.defaults.timeout = 300000
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8'

axios.interceptors.request.use(
    // eslint-disable-next-line no-param-reassign
    (config) => {
        // eslint-disable-next-line no-param-reassign
        config.withCredentials = true;
        const token = window.localStorage.getItem('auth');
        if (token) {
            // eslint-disable-next-line no-param-reassign
            config.headers = {
                Authorization: token,
                'Content-Type': 'application/json;charset=UTF-8',
            };
        }
        return config;
    },
    (error) => Promise.reject(error),
);


axios.interceptors.response.use(
    (response) => {
        // eslint-disable-next-line eqeqeq
        if (response.headers.authorization != undefined) {
            window.localStorage.setItem('auth', response.headers.authorization);
        }
        // eslint-disable-next-line eqeqeq,radix
        if (parseInt(response.data.code) == -7) {
            window.localStorage.removeItem('auth');
            window.localStorage.removeItem('user');
        }
        // eslint-disable-next-line implicit-arrow-linebreak
        return Promise.resolve(response);
    },
    (error) =>
        // 失败请求的状态码
        // console.log(error);
        // eslint-disable-next-line implicit-arrow-linebreak
        Promise.reject(error.response.data)
    ,
);



// 其他页面在使用axios的时候直接  this.$axios就可以了
Vue.prototype.$axios = axios

export default [
    {
        path: '/',
        component: resolve => require(['../views/home/Home.vue'], resolve)
    },
    {
        path: '/login',
        component: resolve => require(['../views/home/QRLoginPage.vue'], resolve)
    },
    {
        path: '/manage',
        component: resolve => require(['../views/home/Manage.vue'], resolve)
    },
];



